import React, { useState } from 'react';
import { UploadExcel } from 'src/utils/dropzoneExcel';
import {
  Paper,
  Typography,
  makeStyles,
  Button,
  Modal
} from '@material-ui/core';
import readXlsxFile from 'read-excel-file';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { BENEFICIARY } from 'src/constants/api';
import { getUserToken } from 'src/utils/sessionManager';
import axios from 'axios';
const access_token = getUserToken();

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 100 },
  {
    id: 'course',
    label: 'Course',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'additional_info',
    label: 'Additional Info',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'address',
    label: 'Address',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toFixed(2)
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexorow: 1,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  container: {
    maxHeight: 440
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  uploadPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  metaDataPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  uploadText: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%'
  },
  addButton: {
    width: 'fit-content',
    marginTop: '1rem',
    marginRight: '1rem'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  uploadButton: {
    float: 'right',
    margin: '2rem'
  }
}));

export default function BulkUpload() {
  const classes = useStyles();
  const [beneficaries, setBeneficaries] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [issuer, setIssuer] = useState('');
  const [snakOpen, setSnakOpen] = useState(false);
  const [sever, setSever] = useState('');
  const [snakMessage, setSnakMessage] = useState('');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function createData(name, email, course, additional_info, address) {
    return { name, email, course, additional_info, address };
  }
  const [formatError, setFormatError] = useState(false);

  const blukIssueBeneficiary = async () => {
    console.log(access_token);
    beneficaries.map(async (beneficary, i) => {
      beneficary.issuer_address = issuer;

      try {
        axios
          .post(BENEFICIARY, beneficary, {
            headers: { access_token: access_token }
          })
          .then((res) => {
            setSnakMessage(i + 1 + 'beneficiary added');
            setSever('success');
            console.log(res);
            setSnakOpen(true);
          })
          .catch((err) => {});
      } catch (err) {
        setSnakMessage('beneficiary could not be added');
        setSever('error');
        setSnakOpen(true);
        console.log(err);
      }
    });
  };

  const issueBeneficiary = async (data) => {
    data.issuer_address = issuer;
    axios
      .post(BENEFICIARY, data, {
        headers: { access_token: access_token }
      })
      .then((res) => {
        setSnakMessage('beneficiary added');
        setSever('success');
        console.log(res);
        setSnakOpen(true);
      })
      .catch((err) => {
        setSnakMessage('beneficiary could not be added');
        setSever('error');
        setSnakOpen(true);
        console.log(err);
      });
  };

  const handleFile = (files) => {
    setIssuer(localStorage.getItem('address'));
    setFormatError(false);
    const list = [];
    readXlsxFile(files[0])
      .then((rows) => {
        rows.forEach((row) => {
          if (row.length < 5) {
            setFormatError(true);
            setOpen(true);
          } else {
            list.push(createData(...row));
          }
        });
        list.shift();
        setBeneficaries(list);
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClick = () => {
    setOpen(true);
  };
  return (
    <div>
      <Paper className={classes.uploadPaper} elevation={1}>
        <UploadExcel handleImages={(files) => handleFile(files)} />
        <Typography className={classes.uploadText} variant="body1">
          Upload Your certificate here !!!
        </Typography>

        <Typography className={classes.uploadText} variant="caption">
          Just drag and drop.
        </Typography>
      </Paper>
      {formatError ? <Alert severity="error">File format invalid</Alert> : ''}
      {beneficaries.length && !formatError ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <Modal
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={() => setOpen(false)}
            >
              <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow key={'1'}>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        {/* <TableCell key={'register'}>Register</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {beneficaries
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.email}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                              {/* <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={async () => {
                                    await issueBeneficiary(row);
                                  }}
                                >
                                  Issue
                                </Button>
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={beneficaries.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Button
                  onClick={() => {
                    blukIssueBeneficiary();
                  }}
                  className={classes.uploadButton}
                  variant="contained"
                >
                  Upload all
                </Button>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={snakOpen}
                  autoHideDuration={6000}
                  onClose={() => {
                    setSnakOpen(false);
                  }}
                >
                  <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                      setSnakOpen(false);
                    }}
                    severity={sever}
                  >
                    {snakMessage}
                  </Alert>
                </Snackbar>

                <Button></Button>
              </Paper>
            </Modal>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </div>
  );
}
