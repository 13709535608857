import { ethers } from 'ethers';
import * as CONSTANTS from 'src/constants';
import { DataService } from 'src/service/db';

export default {
  isValidMnemonic: ethers.utils.isValidMnemonic,

  async connectProvider(wallet) {
    let RPC_URL = CONSTANTS.JSON_RPC_URL.URL;
    const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
    return wallet.connect(provider);
  },

  async loadFromJson(passcode, encryptedJsonWallet) {
    if (!passcode) {
      throw Error('Passcode must be set first');
    }
    const wallet = await ethers.Wallet.fromEncryptedJson(
      encryptedJsonWallet,
      passcode.toString()
    );
    return this.connectProvider(wallet);
  },

  async loadWallet(passcode) {
    const wlt = await DataService.getWallet();
    const res = this.loadFromJson(passcode, wlt);
    return res;
  },

  async create(passcode, mnemonic) {
    if (!passcode) {
      throw Error('Passcode must be set first');
    }
    let wallet = null;
    if (mnemonic) wallet = ethers.Wallet.fromMnemonic(mnemonic);
    else wallet = ethers.Wallet.createRandom();

    wallet = await this.connectProvider(wallet);
    const encryptedWallet = await wallet.encrypt(passcode.toString());
    return { wallet, encryptedWallet };
  },

  async loadFromPrivateKey(privateKey) {
    if (!privateKey) return null;
    let wallet = await new ethers.Wallet(privateKey);
    if (!wallet) throw Error('Wallet not found');
    return this.connectProvider(wallet);
  }
};
