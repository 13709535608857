import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/dashboardLayout';
import AuthProtect from 'src/global/Auth/AuthProtect';
import { ROLES } from '../constants/index';

import ComponentWrapper from 'src/global/ComponentWrapper';
import IssuerTable from 'src/modules/user/details/IssuerTable';
import BulkUpload from 'src/modules/certificate/bulkUpload/BulkUpload';
import Benefeciaries from 'src/modules/beneficiaries/Beneficiaries';
const Uploadcertificate = lazy(() => import('src/modules/certificate/upload'));
const VerifyCertificate = lazy(() => import('src/modules/certificate/details'));
const User = lazy(() => import('src/modules/user/addUser'));
const IssuerDetails = lazy(() =>
  import('src/modules/user/details/IssuerDetails')
);
// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      roles: [ROLES.ADMIN, ROLES.ISSUER],
      guard: AuthProtect,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('src/views/samples/AppDashboard'))
    },

    // APP
    // ----------------------------------------------------------------------
    {
      exact: true,
      roles: [ROLES.ADMIN, ROLES.ISSUER],
      path: PATH_APP.app.beneficiaries,
      heading: 'Beneficiaries',
      guard: AuthProtect,
      breadcrumbs: [
        {
          name: 'Certificate',
          href: PATH_APP.app.beneficiaries
        },
        {
          name: 'Beneficiaries'
        }
      ],
      component: (props) => (
        <ComponentWrapper {...props}>
          {' '}
          <Benefeciaries />
        </ComponentWrapper>
      )
    },
    {
      exact: true,
      roles: [ROLES.ADMIN, ROLES.ISSUER],
      path: PATH_APP.app.bulkUpload,
      heading: 'Upload Certificate In Bulk',
      guard: AuthProtect,
      breadcrumbs: [
        {
          name: 'Certificate',
          href: PATH_APP.app.bulkUpload
        },
        {
          name: 'Bulk Upload'
        }
      ],
      component: (props) => (
        <ComponentWrapper {...props}>
          {' '}
          <BulkUpload />
        </ComponentWrapper>
      )
    },

    {
      exact: true,
      roles: [ROLES.ADMIN, ROLES.ISSUER],
      path: PATH_APP.app.upload,
      heading: 'Upload Certificate',
      guard: AuthProtect,
      breadcrumbs: [
        {
          name: 'Certificate',
          href: PATH_APP.app.upload
        },
        {
          name: 'Upload'
        }
      ],
      component: (props) => (
        <ComponentWrapper {...props}>
          {' '}
          <Uploadcertificate />
        </ComponentWrapper>
      )
    },
    {
      exact: true,
      path: PATH_APP.app.verify,
      roles: [ROLES.ADMIN, ROLES.ISSUER],
      heading: 'Verify Certificate',
      guard: AuthProtect,
      breadcrumbs: [
        {
          name: 'Certificate',
          href: PATH_APP.app.upload
        },
        {
          name: 'Verify'
        }
      ],
      component: (props) => (
        <ComponentWrapper {...props}>
          {' '}
          <VerifyCertificate />
        </ComponentWrapper>
      )
    },
    // ----------------------------------------------------------------------
    // Admin
    // ----------------------------------------------------------------------
    {
      exact: true,
      roles: [ROLES.ADMIN],
      path: PATH_APP.admin.add,
      heading: 'Add Issuer',
      guard: AuthProtect,
      breadcrumbs: [
        {
          name: 'Users',
          href: PATH_APP.admin.add
        },
        {
          name: 'Add Issuer'
        }
      ],
      component: (props) => (
        <ComponentWrapper {...props}>
          <User />
        </ComponentWrapper>
      )
    },
    {
      exact: true,
      path: PATH_APP.admin.issuersDetails,
      roles: [ROLES.ADMIN],
      guard: AuthProtect,
      heading: 'Issuer Details',
      breadcrumbs: [
        {
          name: 'Users',
          href: PATH_APP.admin.issuersDetails
        },
        {
          name: 'Issuer details'
        }
      ],
      component: (props) => (
        <ComponentWrapper {...props}>
          <IssuerDetails {...props} />
        </ComponentWrapper>
      )
    },
    {
      exact: true,
      roles: [ROLES.ADMIN],
      path: PATH_APP.admin.issuers,
      guard: AuthProtect,
      heading: 'Issuers',
      breadcrumbs: [
        {
          name: 'Users',
          href: PATH_APP.admin.issuers
        },
        {
          name: 'Add Issuer'
        }
      ],
      component: (props) => (
        <ComponentWrapper {...props}>
          <IssuerTable />
        </ComponentWrapper>
      )
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
