import React, { createContext, useReducer, useCallback } from 'react';
import * as CONSTANTS from 'src/constants';
import { ethers } from 'ethers';
import * as Service from '../../../service/appSettings';
import appReduce from './AppSettingsReducer';
import * as ContractService from 'src/service/contract';
import * as ACTIONS from './actions';
import ACTION from 'src/action/appSettings';
import { DataService } from '../../../service/db';
import { useHistory } from 'react-router';

const initialState = {
  settings: {
    activeDir: 'ltr',
    activeThemeLayout: 'vertical',
    activeTheme: 'light',
    activeSidebarType: 'full',
    activeLogoBg: 'skin6',
    activeNavbarBg: 'skin1',
    activeSidebarBg: 'skin6',
    activeSidebarPos: 'fixed',
    activeHeaderPos: 'fixed',
    activeLayout: 'full'
  },
  appSettings: { title: 'App Details' },
  tempIdentity: '',
  wallet: null,
  hasWallet: false,
  walletPasscode: null,
  isVerified: false,
  loading: false,
  openPasscodeModal: false,
  walletActionMsg: null
};

export const WalletContext = createContext(initialState);
export const WalletContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReduce, initialState);
  const history = useHistory();

  const initApp = useCallback(async () => {
    let data = await DataService.initAppData();
    data.hasWallet = data.wallet === null ? false : true;
    if (!data.hasWallet) localStorage.removeItem('address');
    dispatch({ type: ACTION.INIT_APP, data });
  }, [dispatch]);

  function getAppSettings() {
    return new Promise((resolve, reject) => {
      Service.getSettings()
        .then((res) => {
          dispatch({ type: ACTION.GET_APP_SUCCESS, res });
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  const getContractWithProvider = useCallback(async () => {
    let RPC_URL = CONSTANTS.JSON_RPC_URL.URL;
    const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
    const contract = await ContractService.getContractByProvider(provider);
    dispatch({ type: ACTIONS.GET_CONTRACT_BY_PROVIDER, data: contract });
    return contract;
  }, [dispatch]);

  const getContractByProvider = useCallback(
    async (args = null) => {
      const provider = args;
      if (!provider) return;
      const contract = await ContractService.getContractByProvider(provider);
      dispatch({ type: ACTIONS.GET_CONTRACT_BY_PROVIDER, data: contract });
      return contract;
    },
    [dispatch]
  );

  const signerService = useCallback(
    async (wallet, passedFunc, params = {}) => {
      console.log('State wallet:', state.wallet);
      let signerContract = await getContractWithProvider();
      const myContract = signerContract.connect(wallet);
      if (!signerContract) {
        return history.push('/');
      }
      return await passedFunc(myContract, params);
    },
    [history, state.signerContract, state.isVerified, state.wallet]
  );

  function setPasscodeModal(flag) {
    dispatch({ type: ACTION.SET_PASSCODE_MODAL, data: flag });
  }

  function setLoading(flag) {
    dispatch({ type: ACTION.SET_LOADING, data: flag });
  }

  function setWalletActionMsg(msg) {
    dispatch({ type: ACTION.SET_WALLET_ACTION_MSG, data: msg });
  }

  function setTempIdentity(tempIdentity) {
    dispatch({ type: ACTION.SET_TEMP_IDENTITY, data: tempIdentity });
  }

  function setHasWallet(hasWallet) {
    dispatch({ type: ACTION.SET_HASWALLET, data: hasWallet });
  }

  function setWallet(wallet) {
    dispatch({ type: ACTION.SET_WALLET, data: wallet });
  }
  function changeIsverified(boolArg) {
    dispatch({ type: ACTION.CHANGE_ISVERIFIED, data: boolArg });
  }
  function setWalletPasscode(passcode) {
    dispatch({ type: ACTION.SET_APP_PASSCODE, data: passcode });
  }

  return (
    <WalletContext.Provider
      value={{
        settings: state.settings,
        appSettings: state.appSettings,
        tempIdentity: state.tempIdentity,
        hasWallet: state.hasWallet,
        wallet: state.wallet,
        walletPasscode: state.walletPasscode,
        isVerified: state.isVerified,
        loading: state.loading,
        openPasscodeModal: state.openPasscodeModal,
        walletActionMsg: state.walletActionMsg,
        setWalletActionMsg,
        setPasscodeModal,
        setLoading,
        getAppSettings,
        setTempIdentity,
        setHasWallet,
        setWallet,
        setWalletPasscode,
        changeIsverified,
        getContractWithProvider,
        getContractByProvider,
        initApp,
        signerService
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
