import React, { useContext, useEffect, useState } from 'react';
import API from '../../../constants/api';
import axios from 'axios';
import { UserContext } from '../core/context';
import { useHistory, useLocation } from 'react-router';
import { BrowserRouter, Link } from 'react-router-dom';
import UnlockWallet from 'src/global/walletUnlock';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Slide,
  Button,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import { getUserToken } from 'src/utils/sessionManager';
const access_token = getUserToken();

const ISSUERS = API.ISSUERS;
const USERS = API.USERS;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  uploadPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  metaDataPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  uploadText: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%'
  },
  addButton: {
    width: 'fit-content',
    alignSelf: 'flex-end',
    marginTop: 'auto'
  }
}));

function createIssuerRow(
  name,
  email,
  phone,
  address,
  wallet_address,
  registered,
  ipfs
) {
  return {
    name,
    email,
    phone,
    address,
    wallet_address,
    registered,
    ipfs
  };
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Row(props) {
  const { row, setUnlock, setParams } = props;
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <BrowserRouter forceRefresh>
        <TableRow hover className={classes.root}>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.address}</TableCell>
          <TableCell align="right">{row.wallet_address}</TableCell>
          <TableCell align="right">
            {row.registered ? (
              'Registered'
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  setParams({
                    name: row.name,
                    address: row.wallet_address,
                    ipfsHash: row.ipfs
                  });
                  setUnlock(true);
                }}
              >
                Register
              </Button>
            )}
          </TableCell>
          <TableCell align="right">
            <Link to={`/admin/issuers/${row.wallet_address}`}>
              <Button variant="contained">View Info</Button>
            </Link>
          </TableCell>
        </TableRow>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default function IssuerTable(props) {
  const classes = useStyles();
  const [issuers, setIssuers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { addIssuerAndApprove } = useContext(UserContext);
  const [dialougeMessege, setDialougeMessege] = useState('');
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [unlock, setUnlock] = useState(false);

  useEffect(() => {
    if (isLoading === false && dialougeMessege === undefined) {
      setDialougeMessege('Failed to add issuer please enter valid details');
    } else if (isLoading === false && dialougeMessege.length > 0) {
      setOpen(true);
    }
  }, [isLoading, dialougeMessege]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(ISSUERS, {
        headers: { access_token: access_token }
      })
      .then((res) => {
        var local_issuers = [];
        res.data.data.forEach((element) => {
          local_issuers.push(
            createIssuerRow(
              element['name'],
              element['email'],
              element['phone'],
              element['address'],
              element['wallet_address'],
              element['is_registered'],
              element['docs'][0]
            )
          );
        });
        setIssuers(local_issuers);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, []);

  const [params, setParams] = useState({});

  return isLoading ? (
    <div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <Typography>Registering user</Typography>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  ) : (
    <>
      <Button
        variant="contained"
        onClick={() => {
          history.push(location.pathname + '/add');
          window.location.reload();
        }}
      >
        Add Issuer
      </Button>
      <br></br>
      <br></br>
      <UnlockWallet
        open={unlock}
        func={addIssuerAndApprove}
        params={params}
        setUnlock={setUnlock}
      />
      <BrowserRouter>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Issuer name</TableCell>
                <TableCell align="right">Address</TableCell>
                <TableCell align="right">Wallet Address</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="center">Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {issuers.map((issuer) => (
                <Row
                  key={issuer.wallet_address}
                  row={issuer}
                  setParams={setParams}
                  setUnlock={setUnlock}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BrowserRouter>
      {}
      <br />
      <br />
      <br />
    </>
  );
}
