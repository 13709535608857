import React, { useState, useEffect } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { uploadBulk, uploadOne } from '../certificate/core/service';
import UnlockWallet from 'src/global/walletUnlock';
import { Paper, makeStyles, Button, Radio } from '@material-ui/core';
import * as EtherUtils from 'src/utils/ethersUtils';
import { Document, Page, pdfjs } from 'react-pdf';
import Table from '@material-ui/core/Table';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { BENEFICIARY } from 'src/constants/api';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import { getUserToken } from 'src/utils/sessionManager';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexorow: 1,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  container: {
    maxHeight: 640
  },
  cardRoot: {
    minWidth: 275,
    maxWidth: 1000,
    position: 'center'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    height: 140,
    width: 100,
    align: 'center',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  uploadPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  metaDataPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  uploadText: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%'
  },
  addButton: {
    width: 'fit-content',
    marginTop: '1rem',
    marginRight: '1rem'
  },
  generateButton: {
    float: 'right',
    margin: '2rem'
  }
}));

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 100 },
  {
    id: 'course',
    label: 'Course',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'additional_info',
    label: 'Additional Info',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'address',
    label: 'Address',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toFixed(2)
  }
];

export default function Benefeciaries() {
  const classes = useStyles();
  const [beneficaries, setBeneficiaries] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [unlock, setUnlock] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Generating pdf');
  const [openModal, setOpenModal] = useState(false);
  const [option, setOption] = React.useState('all');
  const [params, setParams] = useState({});
  const [bulkParams, setBulkParams] = useState({});
  const [bulkUnlock, setBulkUnlock] = useState(false);
  const [snakOpen, setSnakOpen] = useState(false);
  const [sever, setSever] = useState('');
  const [snakMessage, setSnakMessage] = useState('');
  const access_token = getUserToken();

  const registerAll = async () => {
    for (const i in beneficaries) {
      await axios.post(
        BENEFICIARY + '/register/' + beneficaries.at(i).ipfs_hash
      );
    }
    const hashes = [];
    beneficaries.map((item) => {
      let keccakHashes = EtherUtils.textToBytes32(item.ipfs_hash);
      hashes.push(keccakHashes);
    });
    const meta_datas = [];
    beneficaries.map((item) => {
      meta_datas.push(item.meta_data);
    });
    const expDates = [];
    beneficaries.map((item) => {
      expDates.push(0);
    });
    setBulkParams({ hashes, expDates, meta_datas });
    setBulkUnlock(true);
    setOption('all');
    return 'registered all ';
  };

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [ipfsHash, setIpfsHash] = useState(null);

  const bulkGenerate = async () => {
    setLoading(true);
    setLoadingMessage('Generating Certificate...');
    let count = 0;
    let total = 0;
    try {
      const res = await axios.post(
        BENEFICIARY + '/generate',
        {},
        { headers: { access_token: access_token } }
      );
      console.log(res);
      if (res) {
        setLoading(false);
        setSnakMessage(`${res.data.length} Certificates Generated`);
        setSever('success');
        console.log(res);
        setSnakOpen(true);
      }
    } catch (e) {
      setLoading(false);
      setSnakMessage('Error in generating certificate');
      setSever('error');
      setSnakOpen(true);
      console.log(e);
    }

    // for (const i in beneficaries) {
    //   await axios
    //     .post(BENEFICIARY + '/' + beneficaries.at(i)._id)
    //     .then(async (data) => {
    //       total++;
    //       count++;
    //       setLoadingMessage(count + 'certificate generated');
    //       console.log(data.data);
    //     })
    //     .catch((err) => {
    //       total++;
    //       console.log(err);
    //     });
    //   if (total === beneficaries.length) {
    //     setLoading(false);
    //     break;
    //   }
    // }
  };

  const generatePdf = async (row) => {
    setLoadingMessage('Generating Ceritificate');
    setLoading(true);
    axios
      .post(
        BENEFICIARY + '/' + row._id,
        {},
        { headers: { access_token: access_token } }
      )
      .then(async (data) => {
        console.log(data.data);
        setIpfsHash(data.data);
        setLoading(false);
        setOpenModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const issuer = localStorage.getItem('address');
    const list = [];
    axios
      .get(BENEFICIARY, { headers: { access_token: access_token } })
      .then((res) => {
        console.log('data', res);
        res.data.data.map((row) => {
          if (row.issuer_address === issuer) {
            if (option === 'unGenerated' && !row.pdf_generated) {
              list.push(row);
            } else if (
              option === 'generated' &&
              row.pdf_generated &&
              !row.is_registered
            ) {
              list.push(row);
            } else if (option === 'all') {
              list.push(row);
            }
          }
        });
        setBeneficiaries(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [BENEFICIARY, option, openModal, loading]);
  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleClose}
      >
        {loadingMessage}
        <CircularProgress />
      </Backdrop>
      <FormControlLabel
        control={
          <Radio
            checked={option === 'all'}
            onChange={handleChange}
            value="all"
          />
        }
        label="All"
      />
      <FormControlLabel
        control={
          <Radio
            checked={option === 'unGenerated'}
            onChange={handleChange}
            value="unGenerated"
          />
        }
        label="Ungenerated"
      />
      <FormControlLabel
        control={
          <Radio
            checked={option === 'generated'}
            onChange={handleChange}
            value="generated"
          />
        }
        label="Unregistered"
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snakOpen}
        autoHideDuration={6000}
        onClose={() => {
          setSnakOpen(false);
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setSnakOpen(false);
          }}
          severity={sever}
        >
          {snakMessage}
        </Alert>
      </Snackbar>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow key={'1'}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell>Generate Pdf</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {beneficaries
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.email}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      {row.pdf_generated ? (
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setIpfsHash(row.ipfs_hash);
                              setOpenModal(true);
                            }}
                          >
                            {' '}
                            Preview{' '}
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              generatePdf(row);
                            }}
                          >
                            Generate
                          </Button>
                        </TableCell>
                      )}
                      {!row.is_registered && row.pdf_generated ? (
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={async () => {
                              setParams({
                                expiryDate: 0,
                                hash: row.ipfs_hash,
                                metaHash: row.meta_data
                              });
                              setUnlock(true);
                            }}
                          >
                            Register
                          </Button>
                        </TableCell>
                      ) : row.is_registered ? (
                        <TableCell>Registered</TableCell>
                      ) : (
                        <TableCell>Pdf not generated</TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={beneficaries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {ipfsHash && openModal ? (
          <Modal
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              <Card className={classes.cardRoot} variant="outlined">
                <CardHeader
                  title="Certificate has been generated!"
                  subheader={new Date(Date.now()).toDateString()}
                  subheader={'IpfsHash : ' + ipfsHash}
                />
                <CardContent>
                  <CardMedia>
                    <Document file={`https://ipfs.rumsan.com/ipfs/${ipfsHash}`}>
                      <Page pageNumber={1} height={700} width={700} />
                    </Document>
                  </CardMedia>
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.open(
                        `https://ipfs.rumsan.com/ipfs/${ipfsHash}`,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }}
                  >
                    Open in ipfs
                  </Button>
                </CardContent>
              </Card>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {option === 'unGenerated' ? (
          <Button
            variant="contained"
            className={classes.generateButton}
            onClick={() => {
              bulkGenerate();
            }}
          >
            {' '}
            Generate for all
          </Button>
        ) : (
          ''
        )}
        {option === 'generated' ? (
          <Button
            variant="contained"
            onClick={async () => {
              await registerAll();
            }}
          >
            {' '}
            Add all to blockchain
          </Button>
        ) : (
          ''
        )}
        <UnlockWallet
          open={unlock}
          func={uploadOne}
          params={params}
          setUnlock={setUnlock}
        />
        <UnlockWallet
          open={bulkUnlock}
          func={uploadBulk}
          params={bulkParams}
          setUnlock={setBulkUnlock}
        />
      </Paper>
    </div>
  );
}
