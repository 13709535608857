import React, { useState, useEffect, useContext } from 'react';
import Wallet from 'src/utils/wallet';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { APP_CONSTANTS } from '../constants';
import { Modal } from '@material-ui/core';
import { WalletContext } from 'src/modules/certificate/core/AppSettingsContext';
import { Fade } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PasscodeModal from './passcodeInput';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

function ModalMessage({ open, message, setPasscodeOpen }) {
  const classes = useStyles();
  return (
    <Fade in={open}>
      <div className={classes.paper}>{message}</div>
    </Fade>
  );
}

export default function UnlockWallet({ open, setUnlock, func, params }) {
  const classes = useStyles();
  const [passcodeOpen, setPasscodeOpen] = useState(true);
  const [passcode, setPasscode] = useState('');
  const PASSCODE_LENGTH = APP_CONSTANTS.PASSCODE_LENGTH;
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState('Loading');
  const {
    signerService,
    setWallet,
    wallet,
    walletPasscode,
    setWalletPasscode
  } = useContext(WalletContext);

  useEffect(() => {
    if (passcode.length >= PASSCODE_LENGTH) {
      setPasscodeOpen(false);
      validatePasscode();
    }
  }, [passcode]);

  const afterPasswordValid = async (wallet) => {
    setMessage('Performing transaction');
    try {
      const res = await signerService(wallet, func, params);
      console.log(res);
      setMessage(res);
    } catch (err) {
      console.log(err);
      if (err.reason) {
        setMessage(err.reason);
      } else {
        setMessage(
          <div>
            Transaction could't be completed
            <Button
              onClick={() => {
                setPasscodeOpen(true);
                setMessageOpen(false);
              }}
            >
              Try again
            </Button>
          </div>
        );
      }
    }
  };

  const validatePasscode = async () => {
    setMessage('Validating password please wait');
    setMessageOpen(true);
    if (!wallet) {
      try {
        let wlt = await Wallet.loadWallet(passcode);
        console.log('wallet ', wlt);
        setWallet(wlt);
        setWalletPasscode(passcode);
        await afterPasswordValid(wlt);
        setPasscode('');
      } catch (err) {
        setMessage(
          <div>
            invalid password
            <Button
              onClick={() => {
                setPasscodeOpen(true);
                setMessageOpen(false);
              }}
            >
              Try again
            </Button>
          </div>
        );
        setPasscode('');
        console.log(err);
      }
    } else {
      if (passcode !== walletPasscode) {
        setPasscode('');
        setMessage(
          <div>
            invalid password
            <Button
              onClick={() => {
                setPasscodeOpen(true);
                setMessageOpen(false);
              }}
            >
              Try again
            </Button>
          </div>
        );
      } else {
        await afterPasswordValid(wallet);
        setPasscode('');
      }
    }
  };

  return (
    <Modal
      title="Verify passcode"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      open={open}
      onClose={() => {
        setUnlock(false);
        setPasscodeOpen(true);
      }}
    >
      <div>
        {passcodeOpen ? (
          <PasscodeModal
            open={passcodeOpen}
            passcode={passcode}
            setPasscode={setPasscode}
            setPasscodeOpen={setPasscodeOpen}
          />
        ) : (
          ''
        )}
        {messageOpen ? (
          <ModalMessage
            message={message}
            open={messageOpen}
            setMessageOpen={setMessageOpen}
          />
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
}
