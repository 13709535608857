import React, { createContext } from 'react';
// import appReduce from '../reducers/appReducer';

const initialState = {};

export const AppContext = createContext(initialState);
export const AppContextProvider = ({ children }) => {
  // const [state] = useReducer(appReduce, initialState);

  return <AppContext.Provider>{children}</AppContext.Provider>;
};
