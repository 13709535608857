import { ethers } from 'ethers';
import Hash from 'ipfs-only-hash';
/* export const getIpfshash = async (data) => {
  //const unixFs = new UnixFS('file', data);
  const dag = new DAGNode(data);
  const res = await (await dag.toDAGLink()).toJSON();
  return res;
}; */

export const getIpfshash = async (data) => {
  //gives ipfs hash
  return Hash.of(data);
};

export const textToBytes32 = (text) => {
  //gives keccak256 hash pf text
  return ethers.utils.id(text);
};
