module.exports = {
  APP_CONSTANTS: {
    PASSCODE_LENGTH: 6
  },
  APP_NAME: 'Minimal Theme',
  APP_VERSION: '1.0',
  ROLES: { ADMIN: 'Admin', ISSUER: 'Issuer' },
  DB: {
    NAME: 'certify_users',
    VERSION: 1,
    TABLES: {
      DATA: 'tbl_data'
    }
  },
  JSON_RPC_URL: {
    URL: process.env.REACT_APP_NETWORK_URL
  },
  CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS
};
