module.exports = {
  INIT_APP: 'INIT_APP',
  GET_APP_SUCCESS: 'GET_APP_SUCCESS',
  SET_TEMP_IDENTITY: 'SET_TEMP_IDENTITY',
  SET_HASWALLET: 'SET_HASWALLET',
  SET_WALLET: 'SET_WALLET',
  SET_APP_PASSCODE: 'SET_APP_PASSCODE',
  CHANGE_ISVERIFIED: 'CHANGE_ISVERIFIED',
  SET_LOADING: 'SET_LOADING',
  SET_PASSCODE_MODAL: 'SET_PASSCODE_MODAL',
  SET_WALLET_ACTION_MSG: 'SET_WALLET_ACTION_MSG'
};
