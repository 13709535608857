import { ethers } from 'ethers';
import ContractAbi from 'src/contracts/abis/Certify.json';
import * as CONSTANT from 'src/constants';
const ContractAddress = CONSTANT.CONTRACT_ADDRESS;

export const getContractByProvider = async (provider) => {
  try {
    const contract = new ethers.Contract(
      ContractAddress,
      ContractAbi,
      provider
    );
    return contract;
  } catch (err) {
    throw err;
  }
};
export const getContractBySigner = async (signer) => {
  try {
    const contract = new ethers.Contract(ContractAddress, ContractAbi, signer);
    return contract;
  } catch (err) {
    throw err;
  }
};
